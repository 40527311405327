.payments-section-page {
	width: 100%;
	margin: 50px;
	@include centerBox();
	flex-direction: column;
	.btn-back-payments {
		width: 100%;
		max-width: 250px;
		text-decoration: none;
		text-align: center;
		margin-top: 10px;
		display: block;
	}
	.card-body {
		max-width: 450px;
		margin: 20px auto;
		button {
			margin-top: 10px;
			padding: 0.75rem;
			background-color: #198754;
			color: white;
			&:hover {
				background-color: #157046;
			}
		}
	}
}
.payment-container {
	max-width: 450px;
	width: 100%;
	margin: 20px auto;
	.Add-payment-method {
		a {
			padding: 3px 5px;
			text-decoration: none;
			margin: 10px;
		}
	}
	.payment-selection {
		.form-select {
			text-transform: capitalize;
		}
		h3 {
			text-transform: capitalize;
		}
		.card-info {
			text-align: center;
		}
	}

	.btn-container {
		margin-top: 20px;
		@include centerBox(space-evenly, center);
		.btn-cancel {
			text-decoration: none;
			text-align: center;
			color: $greyBlue;
		}
	}
}
