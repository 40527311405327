.index-page {
	padding: 0px 20px 20px;

	.text-container {
		max-width: 700px;
		margin: 0 auto;

		ol {
			margin: 0;
			padding: 0;
		}
		table td,
		table th {
			padding: 0;
		}
		.c0 {
			color: white;
			font-weight: 400;
			text-decoration: none;
			vertical-align: baseline;
			font-size: 16px;
			font-style: normal;
			margin-bottom: 10px;
		}
		.c7 {
			padding-top: 42pt;
			padding-bottom: 8pt;
			line-height: 1.15;
			orphans: 2;
			widows: 2;
			text-align: left;
		}
		.c5 {
			color: white;
			font-weight: 400;
			text-decoration: none;
			vertical-align: baseline;
			font-size: 16px;
			font-family: 'Arial';
			font-style: normal;
		}
		.c4 {
			padding-top: 0pt;
			padding-bottom: 0pt;
			line-height: 1.15;
			orphans: 2;
			widows: 2;
			text-align: left;
			height: 11pt;
		}
		.c2 {
			color: white;
			font-weight: 700;
			text-decoration: none;
			vertical-align: baseline;
			font-size: 16px;
			font-family: 'Arial';
			font-style: normal;
		}
		.c1 {
			padding-top: 12pt;
			padding-bottom: 12pt;
			line-height: 1.15;
			orphans: 2;
			widows: 2;
			text-align: left;
		}
		.c9 {
			padding-top: 18pt;
			padding-bottom: 4pt;
			line-height: 1.15;
			orphans: 2;
			widows: 2;
			text-align: center;
		}
		.c6 {
			color: white;
			text-decoration: none;
			vertical-align: baseline;
			font-size: 17pt;
			font-family: 'Arial';
			font-style: normal;
		}
		.c8 {
			max-width: 468pt;
			width: 100%;
			padding: 20px;
		}
		.c3 {
			font-weight: 700;
		}
		.title {
			padding-top: 0pt;
			color: white;
			font-size: 26pt;
			padding-bottom: 3pt;
			font-family: 'Arial';
			line-height: 1.15;
			page-break-after: avoid;
			orphans: 2;
			widows: 2;
			text-align: left;
		}
		.subtitle {
			padding-top: 0pt;
			color: #ccc;
			font-size: 15pt;
			padding-bottom: 16pt;
			font-family: 'Arial';
			line-height: 1.15;
			page-break-after: avoid;
			orphans: 2;
			widows: 2;
			text-align: left;
		}
		li {
			color: white;
			font-size: 16px;
			font-family: 'Arial';
		}
		p {
			margin: 0;
			color: white;
			font-size: 16px;
			font-family: 'Arial';
		}
		h1 {
			padding-top: 20pt;
			color: white;
			font-size: 20pt;
			padding-bottom: 6pt;
			font-family: 'Arial';
			line-height: 1.15;
			page-break-after: avoid;
			orphans: 2;
			widows: 2;
			text-align: center;
		}
		h2 {
			padding-top: 18pt;
			color: white;
			font-size: 16pt;
			padding-bottom: 6pt;
			font-family: 'Arial';
			line-height: 1.15;
			page-break-after: avoid;
			orphans: 2;
			widows: 2;
			text-align: left;
		}
		h3 {
			padding-top: 16pt;
			color: #ccc;
			font-size: 14pt;
			padding-bottom: 4pt;
			font-family: 'Arial';
			line-height: 1.15;
			page-break-after: avoid;
			orphans: 2;
			widows: 2;
			text-align: left;
		}
		h4 {
			padding-top: 14pt;
			color: #ccc;
			font-size: 12pt;
			padding-bottom: 4pt;
			font-family: 'Arial';
			line-height: 1.15;
			page-break-after: avoid;
			orphans: 2;
			widows: 2;
			text-align: left;
		}
		h5 {
			padding-top: 12pt;
			color: #ccc;
			font-size: 16px;
			padding-bottom: 4pt;
			font-family: 'Arial';
			line-height: 1.15;
			page-break-after: avoid;
			orphans: 2;
			widows: 2;
			text-align: left;
		}
		h6 {
			padding-top: 12pt;
			color: #ccc;
			font-size: 16px;
			padding-bottom: 4pt;
			font-family: 'Arial';
			line-height: 1.15;
			page-break-after: avoid;
			font-style: italic;
			orphans: 2;
			widows: 2;
			text-align: left;
		}
	}
}
