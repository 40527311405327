// Cards home page
.homeCardsWrapper {
	.homeCardContainer {
		background-color: rgba(58, 64, 66, 0.4);
		position: relative;
		margin: 10px 5px;
		border-radius: 2px;
		overflow: hidden;
		cursor: pointer;
		-webkit-box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.5);
		-moz-box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.5);
		box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.5);

		.cardQRIcon {
			position: absolute;
			top: 10px;
			left: 5px;
			z-index: 2;
		}
		.HomeCardName {
			color: white;
			padding: 10px 3px 0 10px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			width: 175px;
			text-transform: capitalize;
		}
		.adminCardName {
			color: white;
			padding: 10px 3px 5px 10px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			width: 175px;
			text-transform: capitalize;
		}
		.expirationDate {
			padding: 5px;
			background-color: rgb(250, 246, 0);
			color: #575757;
			font-size: 14px;
			width: 100%;
			text-align: center;
		}
		.cardGenre {
			color: #aeafb5;
			padding: 0 3px 5px 10px;
			overflow: hidden;

			text-overflow: ellipsis;
			// white-space: nowrap;
			width: 175px;
		}

		&:hover {
			-webkit-box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.5);
			-moz-box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.5);
			box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.5);
		}
	}
}

//  Profile page

.cardName {
	text-transform: capitalize;
	@media (max-width: 767px) {
		font-size: 32px !important;
		line-height: 35px !important;
	}
}

.alingGallery {
	justify-content: center !important;
}

.whatsappStyle {
	text-decoration: none;
	color: white;
	&:hover {
		color: white;
	}
}

//  Events
.btn-group {
	margin: 20px 0;
}

.eventsSyleWDP {
	margin-bottom: 150px;
	a {
		text-decoration: none;
	}
}
.datePicker {
	max-width: 230px;
}

.react-datepicker-popper {
	z-index: 9999999999999999999;
}

// Profile administration

.profilesAdmin {
	width: 100%;
	@include centerBox(flex-start, center);
	a {
		margin: 5px;
	}
}

.btn-delete-profile {
	width: 100%;
	padding: 3px;
	@include centerBox(flex-end, center);
	cursor: initial;
	button {
		margin-bottom: -50px;
		cursor: pointer;
		width: 30px;
		height: 30px;
		background-color: white;
		color: black;
		border-radius: 50%;
		align-items: center;
		padding: 0;
		&:hover {
			background-color: red !important;
		}
	}
}

.btn-profile-cards {
	padding: 5px 2px;
	.btn-hidde {
		background-color: $greyBlue;
		border-color: $greyBlue;
	}
	button {
		border-radius: 0px;
	}
}

//  Create new profile
.imageWeightWarning {
	color: red;
	text-align: center;
	width: 100%;
	font-size: 18px;
	font-weight: 400;
	margin: 10px 0;
}
.wrap-images-group-form-styled {
	@include centerBox();
	width: 100%;
	justify-content: center !important;
	h3 {
		color: red;
		text-align: center;
		width: 100%;
		font-size: 18px;
		font-weight: 400;
		margin-bottom: 20px;
	}
}

.events-list {
	background-color: $transparentWhite;
	margin: 10px;
	margin-bottom: 150px;
	border-radius: 8px;
	cursor: pointer;
	p {
		color: white;
	}
}

//  General

.tabButtons {
	@include centerBox(flex-start, center);
	max-width: 650px;
	width: 100%;
	button {
		width: 150px;
		margin: 5px;
	}
}

//  Modal

.modal-title {
	background-color: $greyBlue !important;
	color: white !important;
}

.modal-content {
	.qrCodeImage {
		background-color: #2f3036;
		width: 200px;
		height: 200px;
		border-radius: 50%;
		border: 1px solid white;
	}
	.modal-header {
		background-color: $greyBlue !important;
		color: white;
	}
	.modal-body {
		background-color: $greyBlue !important;
		div {
			.form-control:focus {
				border: none !important;
			}
			input {
				&:focus {
					outline: none;
					background-color: #2f3036;
				}
			}
		}
	}
	.modal-footer {
		background-color: $greyBlue !important;
		button {
			color: white;
		}
	}
}

.footerStyle {
	@include centerBox();
	div {
		// width: 50%;
		font-size: 16px;
		@media (max-width: 768px) {
			width: 100%;
		}
		&:hover {
			color: $skyBlue;
		}
	}
}
