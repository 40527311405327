.wdpheader {
	background: rgba(58, 64, 66, 0.4) !important;
	margin-bottom: 40px;
}
body > div > a {
	text-decoration: none;
}
.menuLinks {
	@media (max-width: 767px) {
		margin-top: 20px;
	}
	.aLink {
		text-transform: uppercase;

		@media (max-width: 767px) {
			margin: 5px 0;
		}
		:hover {
			color: $navyBlue;
		}
	}

	.logoutButton {
		text-decoration: none;
		text-transform: initial;
		font-size: 14px;
		background-color: rgb(#3a4042, 0.4);
		// text-transform: uppercase;
		@media (max-width: 767px) {
			margin-top: 50px;
		}
	}
}
.menuLinksResponsive a {
	text-decoration: none !important;
	color: white;
	padding: 10px 0;
	&:hover {
		color: #50717b !important;
	}
}
